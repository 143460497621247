@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.btn-add {
  height: 24px;
  width: 24px;

  i {
    &::before {
      font-size: 24px;
    }
  }
}

.campaign-sidebar {
  background-color: #f1f1f1;
}

.campaign-content {
  position: relative;
}

.campaign-title {
  font-size: 10px;
  line-height: 1.1rem;

  // truncation
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.campaign-date {
  position: absolute;
  bottom: 0;
  left: 0;
}

.campaign-img {
  display: block;
  object-fit: cover;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 16px;
}
